//以类的方式传值需要在导入React时加入组件文件夹名称,{组件文件夹名称}
import React, { Component } from 'react';
import './App.css';
import Xxym from './Component/信息管理组件';
import Ggzhym from './Component/公共账户组件';

class App extends Component {

  state = {
    页面管理: "信息管理",
  }

  页面管理 = (event) => {
    this.setState({
      页面管理: event.target.name
    })
  }

  render() {
    return (
      <div className='App'>
        <div className="card">

          {/* <h5 className="card-header">爱管理综合信息系统管理平台。</h5> */}
          <div className="btn-group me-2" role="group" aria-label="First group">
            <button type="button" onClick={this.页面管理} name="信息管理" className="btn btn-outline-secondary" style={{ "margin": 1 }}>信息管理</button>
            <button type="button" onClick={this.页面管理} name="公共账户" className="btn btn-outline-secondary" style={{ "margin": 1 }} >公共账户</button>
          </div>
        </div>

        {
          this.state.页面管理 === "信息管理" ? <Xxym /> : <Ggzhym />
        }
      </div >
    );
  }

}

export default App;
