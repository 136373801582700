import React, { Component } from "react";



class Xjxxym extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            defaults: true
        }
    }

    单选 = (event) => {
        let states = this.state
        states.defaults = !this.state.defaults
        this.setState({
            state: states
        })
        //event.target.name
        console.log(this.state)
    }
    新建 = () => {
        fetch("http://47.243.71.167:8000/xj/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state),
        }).then(res => res.json()).then(
            data => {
                /*                 var datas = {
                                    "code": "0",
                                    "msg": "新建成功！"
                                } */
                var datas = data
                datas.code === 0 ? alert(datas.msg) : alert(datas.msg)
                window.location.reload()
            }
        )
    }
    render() {
        return (
            <div>
                <div className="card text-center">
                    <div className="card-header">
                        信息录入
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">请确认名称是否正确</h5>
                        <p className="card-subtitle mb-2 text-muted">如名称有误请重新刷新页面进行更改</p>
                        <div className="col-auto">
                            <input name="name" className="form-control" style={{ "height": "20%", "line height": "20%", "textAlign": "center" }} type="text" placeholder={this.state.name} aria-label="Disabled input example" disabled></input>
                            <input className="form-check-input" name="defaults" onClick={this.单选} type="checkbox" value="" id="defaultCheck1" defaultChecked={true} />
                            <label className="form-check-label">
                                导入预设默认内容
                            </label>
                        </div>
                        <button className="btn btn-primary" onClick={this.新建}>确认录入</button>
                    </div>

                </div>

            </div >
        )
    }
}

export default Xjxxym;
