import React, { Component } from "react";
import Xjxxym from "./新建信息页面/新建信息组件";
import Cxym from "./新建信息页面/查询组件";
// import qs from 'qs';

class Xxym extends Component {


    state = {
        页面管理: "",
        name: ""
    }

    页面管理 = (event) => {
        // event.preventDefault();

        var datas = { name: this.state.name }

        fetch("http://47.243.71.167:8000/nccx/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(datas),
        }).then(res => res.json())
            .then(data => {
                console.log(data, event.target.name)
                if ((data.code === 0 && event.target.name === "新建信息") || (data.code === 1 && event.target.name === "查询")) {
                    this.setState({
                        页面管理: event.target.name
                    })
                } else {
                    alert(data.msg)
                }
            })

    }

    更新姓名 = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onKeyup = (e) => {
        if (e.keyCode === 13) {

            var datas = { name: this.state.name }

            fetch("http://47.243.71.167:8000/nccx/", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(datas),
            }).then(res => res.json())
                .then(data => {
                    if (data.code === 1) {
                        this.setState({
                            页面管理: "查询",
                        })
                    } else {
                        this.setState({
                            页面管理: "新建信息",
                        })
                    }
                    console.log(data)

                })
        }
    }

    render() {
        return (
            <div style={{ "width": "100%", "height": "100%" }}>

                <div className="card">

                    <h5 className="card-header" style={{ "padding": 1 }}>信息管理</h5>
                    <div className="card-body" style={{ "padding": "2%" }}>


                        {

                            this.state.页面管理 === "新建信息" ? <Xjxxym name={this.state.name} /> : this.state.页面管理 === "查询" ? <Cxym name={this.state.name} /> : <div className="card-body">
                                <input className="form-control form-control-sm" style={{ "height": "20%", "line height": "20%", "textAlign": "center" }} name="name" onChange={this.更新姓名} onKeyUp={this.onKeyup} type="text" placeholder="请输入姓名" aria-label="default input example" />
                                <button type="button" name="新建信息" onClick={this.页面管理} className="btn btn btn-secondary btn-sm" style={{ "margin": 5, "width": "30%" }}>新建信息</button>
                                <button type="button" name="查询" onClick={this.页面管理} className="btn btn-secondary btn-sm" style={{ "margin": 5, "width": "30%" }}>查询</button>
                            </div>
                        }


                    </div>

                </div>
                {

                    this.state.页面管理 === "" ? <div className="spinner-grow text-success" role="status" style={{ "width": "100%", }}>
                        <div className="alert alert-success alert-dismissible fade show">
                            爱管理综合信息系统管理平台
                        </div>

                    </div> : null
                }
            </div>
        )
    }
}

export default Xxym;



