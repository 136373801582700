import React, { Component } from "react";



class Ggzhym extends Component {


    render() {
        return (
            <div>
                <div>公共账户页面，待开发中</div>
            </div>
        )
    }
}

export default Ggzhym;
