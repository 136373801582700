import React, { Component } from "react";
import copy from 'copy-to-clipboard';
import { message } from 'antd';
class Cxym extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            data: [{
                "id": "",
                "title": "",
                "body": "",
            },],
            data1: [],
            修改状态: false,
            请求: true,
            完成数: 0,
            实时点击id: 0,
            prompt: "加载中请稍等",
        }
        this.姓名输入框 = this.姓名输入框.bind(this)
        this.输入框 = this.输入框.bind(this)

    }
    componentDidMount() {
        if (this.state.请求) {
            this.setState({
                请求: !this.state.请求
            })
        }
        this.温馨提示()
        //http://47.243.71.167:8000/xxcx/
        fetch("http://47.243.71.167:8000/xxcx/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state),
        }).then(res => res.json()).then(
            data => {
                /*                 var datas = {
                                    "code": "0",
                                    "data": [{
                                        "id": "0",
                                        "title": "标题内容",
                                        "body": "内容文本反顾地刮大风姑父家挥洒符合是覅固化算贵阿萨德贵说故事的分工会说的功夫阿萨德固深度1户胡SV护士大V觳觫蛋黄酥吃素的话11的生产技术科大V金山毒霸户合适的所有的公司",
                                    }, {
                                        "id": "1",
                                        "title": "标题内容",
                                        "body": "内容文本",
                                    }, {
                                        "id": "2",
                                        "title": "标题内容",
                                        "body": "内容文本",
                                    }, {
                                        "id": "3",
                                        "title": "标题内容",
                                        "body": "内容文本",
                                    }, {
                                        "id": "4",
                                        "title": "标题内容",
                                        "body": "内容文本",
                                    }
                                    ],
                                    "msg": "查询成功！",
                                } */
                var datas = data
                let 完成数 = this.计算进度(datas.data)
                datas.code === 0 ? this.setState({
                    data: [...datas.data],
                    data1: [...datas.data],
                    完成数: 完成数,
                }) : alert(datas.msg)
                this.setState({
                    请求: !this.state.请求
                })
                console.log(this.state)
            }
        )
    }
    温馨提示 = () => {
        fetch("http://47.243.71.167:8000/prompt/").then(res => res.json()).then(data => {
            /*             var datas = {
                            code: "0",
                            data: "每日一问，有助于提高进度哦！",
                            msg: "请求成功！",
                        } */
            var datas = data
            if (datas.code === 0) {
                this.setState({
                    prompt: datas.msg
                })
            } else {
                this.setState({
                    prompt: datas.msg
                })
            }
        })
    }
    计算进度 = (data) => {
        var 计数 = 0
        for (var i in data) {
            if (data[i].body !== "" && data[i].body !== "请输入新内容") {
                计数++
            }
        }
        return 计数
    }

    删除 = (event) => {
        if (this.state.请求) {
            var name = this.props.name
            var ids = event.target.id
            console.log(ids)
            this.state.data.splice(ids, 1)
            var data = this.state.data
            this.setState({
                name: name,
                data: data
            })
            this.修改标签()
        } else {
            console.log("请等待上一个操作结束后再次重试！")
        }

    }

    姓名输入框 = (event) => {
        this.setState({
            name: event.target.value
        })
        console.log(this.state.name)

    }
    取消修改 = () => {
        if (this.props.name !== this.state.name) {
            this.componentDidMount()
        } else {
            this.setState({
                name: this.props.name
            })
        }
    }
    修改昵称 = () => {
        var name = this.props.name
        var names = this.state.name
        let data = {
            name: name,
            names: names,
        }
        if (this.state.请求) {
            this.setState({
                请求: !this.state.请求
            })
            fetch("http://47.243.71.167:8000/nc/", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(res => res.json())
                .then(data => {
                    //返回状态码0为修改成功，1为修改失败
                    // data.code = "0"
                    // data.msg = "修改成功！"
                    if (data.code === 0) {
                        alert(data.msg + "请重新输入修改后的名字查询")
                        window.location.reload();
                    } else {
                        alert(data.msg)
                    }
                    this.setState({
                        请求: !this.state.请求
                    })
                })

        } else {
            alert("正在修改中，请勿重复点击")
        }


    }

    输入框 = (event) => {
        this.添加确认框(event.target.id)
        //console.log(event.target.id, event.target.name, event.target.value)
        let datass = [...this.state.data]
        datass[event.target.id][event.target.name] = event.target.value
        let datas = [...datass]
        this.setState({
            data: datas,
            修改状态: true
        })
    }
    修改标签 = () => {
        var name = this.props.name
        var body = this.state.data
        let data_db = {
            name: name,
            body: body
        }
        if (this.state.请求) {
            this.setState({
                请求: !this.state.请求
            })
            fetch("http://47.243.71.167:8000/xg/", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data_db),
            }).then(res => res.json())
                .then(data => {
                    //返回状态码0为修改成功，1为修改失败
                    // data.code = "0"
                    // data.msg = "修改成功！"
                    if (data.code === 0) {
                        console.log(data.msg)
                        // window.location.reload();
                    } else {
                        console.log(data.msg)
                    }
                    let 完成数 = this.计算进度(this.state.data)
                    this.setState({
                        完成数: 完成数,
                        请求: !this.state.请求,
                        修改状态: false,
                    })
                })
            console.log("请求完毕")

        } else {
            alert("正在修改中，请勿重复点击")
        }
    }
    取消修改标签 = () => {
        this.componentDidMount()
        this.setState({
            修改状态: false,
        })
    }
    删除个人信息 = () => {

        var name = this.props.name
        if (window.confirm('确定要删除' + name + '吗')) {
            this.setState({
                name: name,
            })
            if (this.state.请求) {
                this.setState({
                    请求: !this.state.请求
                })
                fetch("http://47.243.71.167:8000/sc/", {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.state),
                }).then(res => res.json())
                    .then(data => {
                        //返回状态码0为修改成功，1为修改失败
                        // data.code = "0"
                        // data.msg = "删除成功！"
                        if (data.code === 0) {
                            alert(data.msg)
                            window.location.reload();
                        } else {
                            alert(data.msg)
                        }
                        this.setState({
                            请求: !this.state.请求
                        })
                    })

            } else {
                alert("正在修改中，请勿重复点击")
            }
        }
    }
    新建标题 = () => {
        let data = [...this.state.data]
        console.log(data)
        try {
            data.push({
                id: data[data.length - 1].id + 1,
                title: "请输入标题",
                body: "请输入新内容"
            })
        } catch (e) {
            data.push({
                id: 1,
                title: "请输入标题",
                body: "请输入新内容"
            })
        }

        this.setState({
            data: data
        })
    }

    添加确认框 = (index) => {
        this.setState({
            实时点击id: index
        })
    }

    复制 = (body) => {
        copy(body)
        message.destroy();
        message.info("复制成功！:" + body, 5);
    }

    render() {
        return (
            <div>
                <div>

                </div>

                <div className="list-group">

                    {
                        this.state.请求 === false ? <div className="spinner-border text-success" role="status">
                            <div className="spinner-grow spinner-grow-sm" role="status">

                            </div>
                        </div> : <div>
                            <div className="alert alert-success alert-dismissible fade show">
                                {this.state.prompt}
                            </div>
                            <div className="progress">
                                {
                                    <div className="progress-bar" role="progressbar" style={{
                                        "width": (this.state.完成数 / this.state.data.length * 100).toFixed(2) + "%"
                                    }} aria-valuenow={(this.state.完成数 / this.state.data.length * 100).toFixed(2) + "%"} aria-valuemin="0" aria-valuemax="100">{(this.state.完成数 / this.state.data.length * 100).toFixed(2) + "%"}</div >
                                }

                            </div>
                            <div>
                                <nav id="navbar-example2" className="navbar navbar-light bg-light px-3">
                                    <div>
                                        <input type="text" onChange={this.姓名输入框} className="nav nav-pills form-control-plaintext" style={{ "width": "100%" }} name="names" aria-label={this.state.name} value={this.state.name} />
                                        {

                                            this.state.name !== this.props.name ? <div className="toast-body">
                                                {"正在修改姓名:" + this.props.name + ",是否修改为:" + this.state.name + "？"}
                                                <div className="mt-2 pt-2 border-top">
                                                    <button type="button" onClick={this.修改昵称} className="btn btn-primary btn-sm">确定</button>
                                                    <button type="button" onClick={this.取消修改} className="btn btn-secondary btn-sm" style={{ "marginLeft": "5%" }} data-bs-dismiss="toast">取消修改</button>
                                                </div>
                                            </div> : null
                                        }

                                    </div>
                                    <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                        <button onClick={this.删除个人信息} type="button" className="btn btn-outline-danger" style={{ "padding": 0, "marginLeft": "1%", "width": "30%" }}>删除人员信息</button>
                                        <button onClick={this.新建标题} type="button" className="btn btn-outline-success" style={{ "padding": 0, "marginLeft": "1%", "width": "70%" }}>新建标题</button>
                                    </div>

                                    <ul className="nav nav-pills" >
                                        <details className="btn-toolbar">
                                            <summary >快捷跳转(点击展开)</summary>
                                            <div className="btn-group me-2" role="group" aria-label="Second group">

                                                {
                                                    this.state.data.map((post, index) => {
                                                        return (
                                                            <a key={index} className="btn btn-secondary" href={"#scrollspyHeading" + index} style={{ "textAlign": "left" }}>
                                                                <button type="button" className="btn btn-secondary">
                                                                    {index}
                                                                </button>
                                                            </a>

                                                        )
                                                    })
                                                }

                                            </div>
                                        </details>
                                    </ul>
                                </nav>
                                <a className="btn btn-secondary" href={"#scrollspyHeading" + (this.state.data.length - 1)} style={{ "textAlign": "left", "width": "100%" }}>                                                                <button type="button" className="btn btn-secondary">
                                    跳转到末页
                                </button></a>
                                <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example" tabIndex="0">

                                    {
                                        this.state.data.map((post, index) => {
                                            return (
                                                <div key={index}>
                                                    <span className="badge bg-light text-dark" id={"scrollspyHeading" + index} style={{ "padding": "1%", "width": "100%" }}>{index}.{post.title}</span>

                                                    <li className="list-group-item d-flex justify-content-between align-items-start" style={{ "padding": "1%" }} key={index}>
                                                        <div className="ms-2 me-auto" style={{ "width": "90%" }}>
                                                            <div className="fw-bold" >
                                                                <div className="input-group" >
                                                                    <input type="text" onChange={this.输入框} name="title" id={index} className="nav nav-pills form-control-plaintext" style={{ "width": "100%" }} aria-label={this.state.data[index].title} value={this.state.data[index].title} />
                                                                    {/* <input type="text" onInput={this.输入框} aria-label={post.title} defaultValue={post.title} id={index} name="title" className="input-group-text" style={{ "width": "45%" }} /> */}
                                                                    <div className="bd-example" style={{ "paddingLeft": "10%" }}>
                                                                        <details onClick={() => this.复制(post.body)}>
                                                                            <summary>{post.body.slice(0, 15)}..... </summary>
                                                                            <p style={{ "textAlign": "left" }}>{"——" + post.body}</p>
                                                                        </details>
                                                                    </div>
                                                                </div>
                                                                <input type="text" onChange={this.输入框} name="body" id={index} className="nav nav-pills form-control-plaintext" style={{ "width": "100%" }} aria-label={this.state.data[index].body} value={this.state.data[index].body} />
                                                                {/* <input type="text" onInput={this.输入框} aria-label={post.body} defaultValue={post.body} id={index} name="body" className="form-control" style={{ "width": "100%" }} /> */}
                                                            </div>
                                                        </div>
                                                        <div style={{ "float": "lfte" }}>
                                                            <button id={index} onClick={this.删除} type="button" className="btn btn-outline-danger" style={{ "padding": 0, "marginLeft": "1%", "height": "100%" }}>删除</button>
                                                        </div>
                                                        <div id={"div" + index}>
                                                            {
                                                                console.log(this.state.修改状态, this.state.实时点击id, String(index))
                                                            }
                                                            {
                                                                console.log(this.state.实时点击id === String(index))
                                                            }
                                                            {
                                                                this.state.修改状态 && this.state.实时点击id === String(index) ? <div className="toast-body">
                                                                    修改完毕后请点击保存！
                                                                    <div className="mt-2 pt-2 border-top">
                                                                        <button type="button" onClick={this.修改标签} className="btn btn-primary btn-sm">保存</button>
                                                                        <button type="button" onClick={this.取消修改标签} className="btn btn-secondary btn-sm" style={{ "marginLeft": "5%" }} data-bs-dismiss="toast">取消修改</button>
                                                                    </div>
                                                                </div> : null

                                                            }
                                                        </div>
                                                    </li>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div >
                            <div className="alert alert-info alert-dismissible fade show">
                                我是有底线的。
                            </div>
                            <a className="btn btn-secondary" href={"#scrollspyHeading" + (0)} style={{ "width": "100%" }}>                                                                <button type="button" className="btn btn-secondary">
                                跳转到首页
                            </button></a>
                        </div>
                    }
                </div >

            </div>

        )
    }
}

export default Cxym;



